





























































































































































































import { computed, defineComponent } from '@nuxtjs/composition-api';
import {
  BaseButton,
  BaseDivider,
  BaseIcon,
  ToolTip,
  TypeBody,
  ModalDialog,
} from '@bambeehr/pollen';
import CallOutCard from '@/components/CallOutCard/CallOutCard';
import useTierUpgradeNotice from './useTierUpgradeNotice';
import useCompanyBillingInfo from '@/hooks/useCompanyBillingInfo';
import { ProductKey, ServiceKey } from '@/gql/generated';

const upgradePerks = Object.freeze({
  KEEP_HR_MANAGER: 'Keep your HR Manager',
  INCREASED_LEVEL_OF_SERVICE: 'An increased level of service',
  HR_EXPERTISE: 'HR expertise specifically for companies of your size',
});

export default defineComponent({
  name: 'TierUpgradeNoticeModal',
  components: {
    BaseButton,
    BaseDivider,
    BaseIcon,
    CallOutCard,
    ToolTip,
    TypeBody,
    ModalDialog,
  },
  setup() {
    const {
      currentPlan,
      currentPlanIntervalPrice,
      planByEmployeeCount,
      planByEmployeeCountIntervalPrice,
      upcomingBillingChanges,
      staffMembers,
      handleModalClose,
      isModalOpen,
      isTrial,
    } = useTierUpgradeNotice();

    const {
      hasPayrollAddOn,
      currentPayrollTier,
      payrollAddOnPrice: currentPayrollAddOnPrice,
    } = useCompanyBillingInfo();

    const upcomingPayrollAddOnPrice = computed(() => {
      const payrollAddOn = upcomingBillingChanges.value.find(
        (addon) => addon.serviceKey === ServiceKey.Payroll
      );

      return payrollAddOn?.upcomingMonthlyPrice;
    });

    const payrollLabel = computed(() => {
      if (currentPayrollTier.value === ProductKey.PayrollBasic) {
        return 'Payroll Basic';
      }
      if (currentPayrollTier.value === ProductKey.PayrollPremium) {
        return 'Payroll Premium';
      }

      return 'Payroll';
    });

    const perksUponUpgrade = computed(() => {
      // if the plan the user is upgrading from is a T0 they don't get the first perk - "Keep your HR Manager"
      if (currentPlan.value.tierNumber === 0) {
        return [
          upgradePerks.INCREASED_LEVEL_OF_SERVICE,
          upgradePerks.HR_EXPERTISE,
        ];
      }

      return [
        upgradePerks.KEEP_HR_MANAGER,
        upgradePerks.INCREASED_LEVEL_OF_SERVICE,
        upgradePerks.HR_EXPERTISE,
      ];
    });

    return {
      currentPlan,
      currentPlanIntervalPrice,
      planByEmployeeCount,
      planByEmployeeCountIntervalPrice,
      upcomingBillingChanges,
      staffMembers,
      perksUponUpgrade,
      handleModalClose,
      isModalOpen,
      isTrial,
      hasPayrollAddOn,
      payrollLabel,
      currentPayrollAddOnPrice,
      upcomingPayrollAddOnPrice,
    };
  },
});
