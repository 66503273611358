<template>
  <nav
    class="h-full overflow-y-auto transition-transform transform bg-white duration-light lg:transform-none border-r border-solid border-base-300"
    :class="{
      '-translate-x-full': !isNavOpen,
      'translate-x-0': isNavOpen,
    }"
  >
    <div class="flex flex-col max-w-full min-h-full p-2">
      <div class="relative flex items-center gap-3 p-2">
        <BambeeLogo class="flex-shrink-0" />
        <CompanySelect
          class="flex-grow"
          :active="companyId"
          :companies="allCompanies"
          :light="false"
          @input="handleCompanyChange"
        />
      </div>
      <ul class="flex flex-col gap-1">
        <li
          v-for="{
            label,
            icon,
            to,
            handler,
            tag,
            key,
            tagColor = 'caribbean',
            preventDelinquent,
            activeSubLabel,
            isActive,
            ...rest
          } in navItems"
          :key="key || icon"
          v-bind="rest"
          class="flex -mx-2"
        >
          <NavItem
            class="flex-grow"
            :handler="handler"
            :icon="icon"
            :to="to"
            :locked="
              preventDelinquent &&
              lockLongStandingDelinquent &&
              isLongStandingDelinquent
            "
            locked-tooltip="Update your payment to gain access"
          >
            <div>
              <div>
                {{ label }}
              </div>
              <div
                v-if="activeSubLabel && isActive"
                class="font-normal text-base-500 text-11"
              >
                {{ activeSubLabel }}
              </div>
            </div>
            <CounterBadge
              v-if="incompleteTasksCount && key === NavItemKeys.DASHBOARD"
              :value="incompleteTasksCount"
              variant="secondary"
              class="ml-auto"
            />
            <BaseTag v-if="tag" :color="tagColor" size="small" class="ml-auto">
              {{ tag }}
            </BaseTag>
          </NavItem>
        </li>
      </ul>

      <div class="mt-auto">
        <template v-if="isHrRequestInLeftNav">
          <AdvisorCard class="border-t border-primary-light" />
          <RedesignModal />
        </template>
        <UserSubNav v-else class="-mx-2" />
      </div>
    </div>
  </nav>
</template>

<script>
import { BaseTag, CounterBadge } from '@bambeehr/pollen';
import BambeeLogo from '@/components/BambeeLogo/BambeeLogo.vue';
import launchDarkly from '@bambeehr/vue-launch-darkly';
import get from 'lodash/get';
import { mapActions, mapGetters, mapState } from 'vuex';
import CompanySelect from '@/components/default-layout/CompanySelect';
import NavItem from '@/components/default-layout/NavItem';
import FeatureFlags from '@/constants/FeatureFlags';
import Store from '@/constants/Store';
import bam from '@/lib/bam';
import { CABINET, DASHBOARD } from '@/lib/globals/routes';
import {
  canAccessEmployeePayroll,
  canAccessEmployerPayroll,
} from '@/modules/payroll';
import {
  useEmployerBenefitsAccess,
  useStaffBenefitsAccess,
} from '@/modules/Benefits';
import {
  canAccessEmployerTimeAndAttendance,
  canAccessEmployeeTimeAndAttendance,
  showEmployerTimeAndAttendanceUpsell,
  hasDeputyPremiumPro,
} from '@/modules/TimeAndAttendance';
import { canAccessTrainingModule } from '@/modules/Training';
import {
  canReserve as canReservePayroll,
  hasReserved as hasReservedPayroll,
} from '@/utils/get-payroll/getPayrollReservationStatus';
import {
  canAccessVoices,
  getEmployeeVoicesState,
} from '@/modules/EmployeeVoices/helpers/canAccessVoices';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import useFeeds from '@/modules/TaskCenter/hooks/useFeeds';
import isTrainingEmployerRole from '@/modules/Training/helpers/isTrainingEmployerRole';
import useTierZeroTasks from '@/modules/TaskCenter/hooks/useTierZeroTasks/useTierZeroTasks';
import useIntercom from '@/hooks/useIntercom/useIntercom';
import useGoals from '@/modules/TaskCenter/hooks/useGoals/useGoals';
import usePlanAccess from '@/hooks/usePlanAccess/usePlanAccess';
import useManagedServices from '@/modules/ManagedServices/hooks/useManagedServices';

const AdvisorCard = () => import('@/components/default-layout/AdvisorCard');
const RedesignModal = () =>
  import('@/components/modals/RedesignModal/RedesignModal');
const UserSubNav = () => import('@/components/default-layout/UserSubNav');

const { hasBenefits, upsellBenefits } = useEmployerBenefitsAccess();
const { canAccess: canAccessStaffBenefits } = useStaffBenefitsAccess();

const NavItemKeys = Object.freeze({
  DASHBOARD: 'dashboard',
  DASHBOARD_HOTLINE: 'dashboard-hotline',
  REQUESTS: 'requests',
  CONVERSATIONS: 'conversations',
  STAFF: 'staff',
  HR_POLICY: 'hr-policy',
  CABINET: 'cabinet',
  TRAINING_PORTAL: 'training-portal',
  REPORT_CARDS: 'report-cards',
  VOICES: 'voices',
  TIME_AND_ATTENDANCE: 'timeAndAttendance',
  MY_TIME: 'myTime',
  PAYROLL: 'payroll',
  MY_PAYROLL: 'myPayroll',
  BENEFITS: 'benefits',
  MY_BENEFITS: 'myBenefits',
  GET_BENEFITS: 'getBenefits',
  INSURANCE: 'insurance',
  PERFORMANCE_REVIEWS: 'performance-reviews',
  RESERVE_PAYROLL: 'reserve-payroll',
  MANAGED_SERVICES: 'managed-services',
  MEETINGS: 'meetings',
});

export default defineComponent({
  components: {
    AdvisorCard,
    BaseTag,
    CompanySelect,
    CounterBadge,
    NavItem,
    RedesignModal,
    UserSubNav,
    BambeeLogo,
  },
  props: {
    isNavOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { totalFeedCount } = useFeeds();
    const { incompleteTasksCount: tierZeroTaskCount } = useTierZeroTasks();
    const { shouldShowNewMessageBadge, unreadMessagesCount } = useIntercom();
    const { incompleteGoals, setCurrentGoal } = useGoals();
    const { planAccessMap, isBambeeLite, hasBasicAccess } = usePlanAccess();
    const {
      hasActiveSubscription: hasManagedServices,
      hasCreditGrant: hasManagedServicesCredits,
    } = useManagedServices();

    const incompleteTasksCount = computed(() => {
      if (tierZeroTaskCount.value) {
        return tierZeroTaskCount.value + totalFeedCount.value;
      }

      return totalFeedCount.value + incompleteGoals.value.length;
    });

    return {
      unreadMessagesCount,
      shouldShowNewMessageBadge,
      totalFeedCount,
      incompleteTasksCount,
      setCurrentGoal,
      DASHBOARD,
      NavItemKeys,
      planAccessMap,
      isBambeeLite,
      hasBasicAccess,
      hasManagedServices,
      hasManagedServicesCredits,
    };
  },

  data() {
    return {
      showEmployerPayroll: false,
      showEmployeePayroll: false,
      showTraining: false,
      showVoices: false,
      showEmployerTimeAndAttendance: false,
      showEmployeeTimeAndAttendance: false,
      showEmployerTimeAndAttendanceUpsell: false,
      hasDeputyPremiumPro: false,
    };
  },
  computed: {
    ...mapGetters({
      allCompanies: 'companies/all',
      companyId: 'companyId',
      isCompanyAdmin: Store.auth.Getters.IS_COMPANY_ADMIN,
      isEmployee: Store.auth.Getters.IS_EMPLOYEE,
      isManager: Store.auth.Getters.IS_MANAGER,
      isPolicyApprover: Store.auth.Getters.IS_POLICY_APPROVER,
      isSubscribed: Store.auth.Getters.IS_SUBSCRIBED,
      isLongStandingDelinquent: Store.auth.Getters.IS_LONG_STANDING_DELINQUENT,
    }),
    ...mapState({
      currentUser: 'current_user',
    }),
    showEmployerBenefitsUpsell() {
      return upsellBenefits.value;
    },
    showEmployerBenefits() {
      return hasBenefits.value;
    },
    showStaffBenefits() {
      return canAccessStaffBenefits.value;
    },
    // Feature flags
    isHrRequestInLeftNav() {
      return launchDarkly.getFlags()[
        FeatureFlags.DISPLAY_HR_REQUESTS_IN_LEFT_NAV
      ];
    },
    canSeeMeetings() {
      return launchDarkly.getFlags()[FeatureFlags.MEETINGS_FEATURE];
    },
    enableManagedServices() {
      return launchDarkly.getFlags()[
        FeatureFlags.ENABLE_MANAGED_SERVICES_MARKETING
      ];
    },
    lockLongStandingDelinquent() {
      const lockDelinquentFlag =
        launchDarkly.getFlags()[FeatureFlags.LOCK_LONG_STANDING_DELINQUENTS] ||
        false;

      return this.isCompanyAdmin && lockDelinquentFlag;
    },
    timeAndAttendanceRoute() {
      if (this.planAccessMap.timeAndAttendance?.isUpsell) {
        return '/upgrade/time-and-attendance';
      }
      if (this.hasDeputyPremiumPro) {
        return '/time-and-attendance';
      }

      return '/get-time-and-attendance';
    },

    navItems() {
      const sortOrder = [
        NavItemKeys.DASHBOARD,
        NavItemKeys.REQUESTS,
        NavItemKeys.CONVERSATIONS,
        NavItemKeys.MEETINGS,
        NavItemKeys.STAFF,
        NavItemKeys.HR_POLICY,
        NavItemKeys.CABINET,
        NavItemKeys.TRAINING_PORTAL,
        NavItemKeys.REPORT_CARDS,
        NavItemKeys.VOICES,
        NavItemKeys.TIME_AND_ATTENDANCE,
        NavItemKeys.MY_TIME,
        NavItemKeys.PAYROLL,
        NavItemKeys.MY_PAYROLL,
        NavItemKeys.RESERVE_PAYROLL,
        NavItemKeys.BENEFITS,
        NavItemKeys.MY_BENEFITS,
        NavItemKeys.GET_BENEFITS,
        NavItemKeys.INSURANCE,
        NavItemKeys.MANAGED_SERVICES,
      ];
      const bySortOrder = (a, b) => {
        const aIndex = sortOrder.indexOf(a.key);
        const bIndex = sortOrder.indexOf(b.key);

        // In the case of missing to add a key to the sort order push items without a sort order to the end
        const isSortOrderDefined = aIndex !== -1 && bIndex !== -1;
        if (!isSortOrderDefined || aIndex > bIndex) {
          return 1;
        }

        if (aIndex < bIndex) {
          return -1;
        }

        return 0;
      };

      return [
        !this.isSubscribed && {
          label: 'About Bambee',
          key: NavItemKeys.ABOUT_BAMBEE,
          icon: 'lightbulb',
          to: '/about-bambee',
        },
        this.planAccessMap?.dashboard?.showInNav &&
          this.isSubscribed && {
            label: DASHBOARD.label,
            key: NavItemKeys.DASHBOARD,
            icon: 'checkCircle',
            handler: () => {
              this.setCurrentGoal();
            },
            to: DASHBOARD.path,
          },

        // Same name, but this dashbaord is for hotline-only costomers
        this.planAccessMap?.hotline?.showInNav &&
          this.isSubscribed && {
            label: DASHBOARD.label,
            key: NavItemKeys.DASHBOARD_HOTLINE,
            icon: 'checkCircle',
            to: '/dashboard/lite',
          },
        this.isSubscribed &&
          !this.isHrRequestInLeftNav && {
            label: 'Requests',
            key: NavItemKeys.REQUESTS,
            icon: 'request',
            to: this.planAccessMap?.hrRequests?.isUpsell
              ? '/upgrade/requests'
              : '/requests',
            preventDelinquent: true,
          },
        // This is the "old" conversations / intercom nav item
        // We will be renaming this nav item
        this.hasBasicAccess &&
          this.isSubscribed && {
            label: 'Conversations',
            key: NavItemKeys.CONVERSATIONS,
            icon: 'comment',
            handler: () => {
              try {
                Intercom('showMessages');
              } catch (err) {
                window.DD_RUM.addError('INTERCOM_FAILED_SHOW', {
                  err,
                });
              }
              bam.track('button_clicked', {
                location: 'nav-conversations',
                action: 'open-conversations',
              });
            },
            tag: this.shouldShowNewMessageBadge
              ? `${this.unreadMessagesCount || 1}+`
              : '',
            tagColor: 'tangerine',
            preventDelinquent: true,
          },
        this.canSeeMeetings &&
          this.isCompanyAdmin && {
            label: 'Meetings',
            key: NavItemKeys.MEETINGS,
            icon: 'users',
            to: '/meetings',
            preventDelinquent: true,
          },
        this.planAccessMap?.conversations?.showInNav &&
          this.isBambeeLite && {
            label: 'Conversations',
            key: NavItemKeys.CONVERSATIONS,
            icon: 'comment',
            to: '/conversations',
            preventDelinquent: true,
          },
        this.planAccessMap?.policies?.showInNav &&
          this.isPolicyApprover && {
            label: 'HR Policy',
            key: NavItemKeys.HR_POLICY,
            icon: 'clipboardCheck',
            to: this.isSubscribed ? '/policy' : '/about-bambee/about-policies',
            preventDelinquent: true,
          },
        // Currently showing to all admins
        // Only showing to EEs if they are enrolled
        // We will be updating this soon to show to all EEs
        this.planAccessMap?.training?.showInNav &&
          ((this.showTraining && this.isEmployee) || !this.isEmployee) && {
            label: 'Training',
            key: NavItemKeys.TRAINING_PORTAL,
            icon: 'usersClass',
            to: isTrainingEmployerRole(this.$store)
              ? '/training'
              : '/my-training',
          },
        this.planAccessMap?.staff?.showInNav &&
          this.isManager && {
            label: 'Staff',
            key: NavItemKeys.STAFF,
            icon: 'user',
            to: this.isSubscribed ? '/employee' : '/about-bambee/about-folders',
            preventDelinquent: true,
          },
        this.planAccessMap?.reportCards?.showInNav &&
          this.showReportCards && {
            label: 'Report Cards',
            key: NavItemKeys.REPORT_CARDS,
            icon: 'ballotCheck',
            to: (() => {
              if (this.isSubscribed) {
                if (this.planAccessMap?.reportCards?.isUpsell) {
                  return '/upgrade/report-cards';
                }

                return '/report-cards';
              }

              return '/about-bambee/about-reportcards';
            })(),
            preventDelinquent: true,
          },
        this.planAccessMap?.voices?.showInNav &&
          this.showVoices &&
          this.isSubscribed && {
            label: 'Voices',
            key: NavItemKeys.VOICES,
            icon: 'megaphone',
            to: this.planAccessMap?.voices?.isUpsell
              ? '/upgrade/voices'
              : '/voices',
            preventDelinquent: true,
          },
        // TODO: figure out logic for showReviews
        this.showReviews && {
          label: 'Performance Reviews',
          key: NavItemKeys.PERFORMANCE,
          icon: 'analytics',
          to: this.isSubscribed
            ? '/employee-review'
            : '/about-bambee/about-employee-review',
          preventDelinquent: true,
        },
        this.planAccessMap?.cabinet?.showInNav && {
          label: 'Cabinet',
          key: NavItemKeys.CABINET,
          icon: 'cabinetFiling',
          to: this.isSubscribed ? CABINET : '/about-bambee/about-cabinet',
        },
        this.planAccessMap?.payroll?.showInNav &&
          this.showReservePayroll && {
            label: 'Payroll',
            key: NavItemKeys.RESERVE_PAYROLL,
            icon: 'money',
            handler: () => {
              bam.track('slot-nav-clicked');
            },
            to: this.planAccessMap?.payroll?.isUpsell
              ? '/upgrade/payroll'
              : '/get-payroll',
          },
        // Customer hasn't been selected for benefits onboarding
        // Show general benefits marketing page to schedule a call
        !this.isEmployee &&
          (this.showEmployerBenefitsUpsell ||
            this.planAccessMap.benefits?.isUpsell) && {
            label: 'Benefits',
            key: NavItemKeys.GET_BENEFITS,
            icon: 'heart',
            handler: () => {
              bam.track('get-benefits-clicked-interest');
            },
            subtext: 'Learn about benefits',
            to: this.planAccessMap?.benefits?.isUpsell
              ? '/upgrade/benefits'
              : '/get-benefits',
          },
        this.planAccessMap?.insurance?.showInNav &&
          this.showInsurance && {
            label: 'Insurance',
            key: NavItemKeys.INSURANCE,
            icon: 'umbrella',
            to: this.isEmployee ? '/personal-insurance' : '/insurance',
            preventDelinquent: true,
          },
        (this.planAccessMap.timeAndAttendance?.isUpsell ||
          this.showEmployerTimeAndAttendance) && {
          label: 'Time & Attendance',
          key: NavItemKeys.TIME_AND_ATTENDANCE,
          icon: 'clockLine',
          to: this.timeAndAttendanceRoute,
          preventDelinquent: true,
          activeSubLabel: this.planAccessMap.timeAndAttendance?.isUpsell
            ? ''
            : 'Powered by Deputy',
        },
        !this.planAccessMap.timeAndAttendance?.isUpsell &&
          !this.showEmployerTimeAndAttendance &&
          !this.showEmployeeTimeAndAttendance &&
          !this.isEmployee && {
            label: 'Time & Attendance',
            key: NavItemKeys.TIME_AND_ATTENDANCE,
            icon: 'clockLine',
            to: '/get-time-and-attendance',
            preventDelinquent: true,
          },
        this.planAccessMap?.timeAndAttendance?.showInNav &&
          this.showEmployeeTimeAndAttendance &&
          !this.showEmployerTimeAndAttendance && {
            label: 'Time & Attendance',
            key: NavItemKeys.MY_TIME,
            icon: 'clockLine',
            to: '/my-time',
            preventDelinquent: true,
            activeSubLabel: 'Powered by Deputy',
          },
        // planAccessMap shouldn't hide the payroll tab if the user has already setup payroll in the past
        // This is because they need to access historical payroll data, even if they've downgraded their plan
        this.showEmployerPayroll && {
          label: 'Payroll',
          key: NavItemKeys.PAYROLL,
          icon: 'money',
          to: '/payroll',
          preventDelinquent: true,
        },
        // planAccessMap shouldn't hide the payroll tab if the user has already setup payroll in the past
        // This is because they need to access historical payroll data, even if they've downgraded their plan
        this.showEmployeePayroll && {
          label: 'My Pay',
          key: NavItemKeys.MY_PAYROLL,
          icon: 'money',
          to: '/my-pay',
          preventDelinquent: true,
        },
        this.planAccessMap?.benefits?.showInNav &&
          !this.isEmployee &&
          this.showEmployerBenefits && {
            label: 'Benefits',
            key: NavItemKeys.BENEFITS,
            icon: 'heart',
            to: this.planAccessMap?.benefits?.isUpsell
              ? '/upgrade/benfits'
              : '/benefits',
            preventDelinquent: true,
          },
        this.planAccessMap?.benefits?.showInNav &&
          this.showStaffBenefits && {
            label: 'My Benefits',
            key: NavItemKeys.MY_BENEFITS,
            icon: 'heart',
            to: '/my-benefits',
            preventDelinquent: true,
          },
        this.enableManagedServices &&
          !this.isEmployee &&
          this.planAccessMap?.managedServices?.showInNav && {
            label: 'Managed Services',
            key: NavItemKeys.MANAGED_SERVICES,
            icon: 'ticket',
            to:
              this.hasManagedServices || this.hasManagedServicesCredits
                ? '/managed-services/explore'
                : '/managed-services',
            preventDelinquent: false,
          },
      ]
        .filter(Boolean)
        .map((item) => ({
          ...item,
          isActive: item.to && this.$route.path.includes(item.to),
        }))
        .sort(bySortOrder);
    },

    showInsurance() {
      const company = this.currentUser._company;

      return this.isEmployee
        ? company.settings.personal_insurance_enabled
        : company.settings.insurance_enabled;
    },

    showReservePayroll() {
      return !this.showEmployerPayroll && canReservePayroll(this.currentUser);
    },

    alreadyReservedPayroll() {
      const { _company: company } = this.currentUser;

      return hasReservedPayroll(company);
    },

    showReportCards() {
      const settings = get(this.currentUser, '_company.settings', {});
      const { bciToHRPracticeMigrated, show_report_card: showReportCard } =
        settings;

      // SHOW IF COMPANY WAS MIGRATED FROM BCI TO HR PRACTICE
      if (
        bciToHRPracticeMigrated &&
        launchDarkly.getFlags()[FeatureFlags.ENABLE_BCI_TO_HR_COMPLIANCE]
      ) {
        return true;
      }

      // HIDE IF COMPANY DOESN'T ALLOW REPORT CARDS
      return !!showReportCard;
    },
    showReviews() {
      const settings = get(this.currentUser, '_company.settings', {});
      const {
        show_employee_review: showEmployeeReview,
        employee_review: employeeReview,
      } = settings;

      if (!showEmployeeReview) {
        return false;
      }
      if (this.isCompanyAdmin) {
        return true;
      }
      if (this.isManager && employeeReview) {
        return true;
      }

      return false;
    },
  },
  methods: {
    ...mapActions({
      fetchFeeds: 'feeds/fetchFeeds',
      switchCompany: Store.auth.Actions.SWITCH_COMPANY,
    }),
    async runAsyncGuards() {
      this.showEmployeePayroll = await canAccessEmployeePayroll(this.$store);
      this.showEmployerPayroll = await canAccessEmployerPayroll(this.$store);
      this.showEmployerTimeAndAttendanceUpsell =
        await showEmployerTimeAndAttendanceUpsell(this.$store);
      this.showEmployerTimeAndAttendance =
        await canAccessEmployerTimeAndAttendance(this.$store);
      this.showEmployeeTimeAndAttendance =
        await canAccessEmployeeTimeAndAttendance(this.$store);
      this.hasDeputyPremiumPro = await hasDeputyPremiumPro(this.$store);
      this.showTraining = await this.canAccessTraining();
      const voicesManuallyDisabled = await getEmployeeVoicesState(
        this.companyId
      );
      this.showVoices =
        (await canAccessVoices(this.companyId)) &&
        !voicesManuallyDisabled.isManuallyDisabled;
    },
    async canAccessTraining() {
      const { getters } = this.$store;
      // eslint-disable-next-line no-underscore-dangle
      const userId = getters?.currentUser._id;
      const canAccess = await canAccessTrainingModule(userId, this.$store);

      return canAccess;
    },
    async handleCompanyChange(companyId) {
      try {
        await this.switchCompany(companyId);
      } catch (err) {
        this.$toast.open({
          type: 'is-danger',
          position: 'is-bottom',
          message: err,
        });
      }
    },
  },
  async created() {
    await this.runAsyncGuards();
    if (this.$route.path !== '/task-center') {
      this.fetchFeeds();
    }
  },
});
</script>

<style scoped>
.company-select {
  /* 3.25rem is the width and margin of the logo. */
  max-width: calc(100% - 3.25rem);
}

.counter-badge {
  @apply rounded-sm;
}
</style>
