import { ref, computed } from '@nuxtjs/composition-api';
import {
  useGetScheduledEventsQuery,
  ScheduledEvent,
  SchedulerEventLocation,
  useGetRescheduleLinkQuery,
  useCancelScheduledEventMutation,
} from '@/gql/generated';
import {
  useApolloMutation,
  useApolloQuery,
} from '@/gql/apolloWrapper/apolloWrapper';
import useCurrentUser from '@/hooks/useCurrentUser';
import isBefore from 'date-fns/isBefore';
import { saveInLocalStorage, getFromLocalStorage } from '@/utils/localStorage';
import { fetchCalendlyData } from '@/components/scheduler/CalendlyEvent/useCalendlyEvent';
import format from 'date-fns/format';
import DateFormat from '@/constants/DateFormat';

export enum Events {
  MEETINGS_LIST_VIEWED = 'MEETINGS_LIST_VIEWED',
  MEETINGS_LIST_PAGE_VIEWED = 'MEETINGS_LIST_PAGE_VIEWED',
  MEETINGS_RESCHEDULE_CLICKED = 'MEETINGS_RESCHEDULE_CLICKED',
  MEETINGS_RESCHEDULED = 'MEETINGS_RESCHEDULED',
  MEETINGS_CANCEL_CLICKED = 'MEETINGS_CANCEL_CLICKED',
  MEETINGS_CANCELLED = 'MEETINGS_CANCELLED',
  MEETINGS_CANCELLATION_ERROR = 'MEETINGS_CANCELLATION_ERROR',
  MEETINGS_DUPLICATE_EVENT = 'MEETINGS_DUPLICATE_EVENT',
  MEETINGS_VIEWED_PAST_TAB = 'MEETINGS_VIEWED_PAST_TAB',
  MEETINGS_VIEWED_UPCOMING_TAB = 'MEETINGS_VIEWED_UPCOMING_TAB',
}

const STUB_EVENT_KEY = 'stubEvents';
const CANCELLED_EVENT_KEY = 'cancelledEvents';

const { currentUserId } = useCurrentUser();

const refreshCount = ref(1);

interface CalendlyEvent {
  payload: {
    event: {
      uri: string;
      start_time: string;
      end_time: string;
    };
    invitee: {
      uri: string;
    };
  };
}

type LSScheduledEvent = ScheduledEvent & { addedAt: boolean };

let init;
let store;

const isLoading = ref(false);
const isReloading = ref(false);

export const getCallTypeFromCalendlyUrl = (url: string) => {
  const match = url.match(/\/([^/?]+)\?/);

  return match ? match[1] : url;
};

const getStoredEvents = (userId: string) =>
  JSON.parse(getFromLocalStorage(`${userId}:${STUB_EVENT_KEY}`) ?? null) || [];

const getCancelledEvents = (userId: string) =>
  JSON.parse(getFromLocalStorage(`${userId}:${CANCELLED_EVENT_KEY}`) ?? null) ||
  [];

const getCalendlyEventIdFromUri = (uri: string) => {
  return uri.split('/').pop();
};

export const getDateInformation = (start: string, end: string) => {
  const startTime = new Date(start);
  const endTime = new Date(end);

  const dayNumber = format(startTime, DateFormat.DAY_OF_MONTH);
  const month = format(startTime, DateFormat.MONTH_SHORT);
  const fullDateTime = `${format(startTime, DateFormat.DAY_TIME)} - ${format(
    endTime,
    DateFormat.TIME
  )}`;
  const fullDate = `${format(startTime, DateFormat.MMM_DD_YYYY)}`;
  const fullTime = `${format(startTime, DateFormat.TIME)} - ${format(
    endTime,
    DateFormat.TIME
  )}`;
  const longDateTime = `${format(
    startTime,
    DateFormat.MMM_DD_YYYY
  )} at ${format(startTime, DateFormat.TIME)}`;

  return {
    dayNumber,
    month,
    fullDateTime,
    longDateTime,
    fullDate,
    startTime,
    fullTime,
    endTime,
  };
};

// UTIL FNS

// Takes a Calendly Event and returns a ScheduledEvent which can be stored in LS and shown as a stub to the user
export const getScheduledEventFromCalendlyEvent = async (
  calendlyEvent: CalendlyEvent,
  calendlyUrl: string = ''
): Promise<ScheduledEvent> => {
  const meetingId = getCalendlyEventIdFromUri(
    calendlyEvent?.payload?.event?.uri
  );
  const kind = getCallTypeFromCalendlyUrl(calendlyUrl);

  const inviteeId =
    getCalendlyEventIdFromUri(calendlyEvent.payload.invitee.uri) || '';

  const details = await fetchCalendlyData(
    store,
    getCalendlyEventIdFromUri(calendlyEvent.payload.event.uri)
  );

  return {
    callSummaries: [],
    calendlyEventId: meetingId as string,
    calendlyInviteeId: inviteeId as string,
    startTime: details.startTime,
    endTime: details.endTime,
    cancelled: false,
    title: details.name,
    id: '',
    eventLocation: SchedulerEventLocation.Unknown,
    kind,
  };
};

export const filterEvents = (events: ScheduledEvent[], direction: -1 | 1) => {
  const showPast = direction === -1;

  const eventGroup = showPast
    ? events.filter((e) => isBefore(new Date(e.endTime), new Date()))
    : events.filter((e) => isBefore(new Date(), new Date(e.endTime)));

  return eventGroup
    .filter((e) => !e.cancelled)
    .sort((a, b) =>
      isBefore(new Date(a.startTime), new Date(b.startTime)) ? -1 : 1
    );
};

export const applyLsEvents = (
  events: LSScheduledEvent[],
  stubs: LSScheduledEvent[],
  cancelledEventIds: string[] = []
) => [
  ...events.map((e) => ({
    ...e,
    cancelled: e.cancelled || cancelledEventIds.includes(e.id),
  })),
  ...stubs.filter(
    (e) => !events.some((ev) => ev.calendlyEventId === e.calendlyEventId)
  ),
];

const events = ref<ScheduledEvent[]>([]);

const allEvents = computed(
  () =>
    // Refresh counter makes sure that we are refreshing reactive state when we call the refresh function
    (!!refreshCount.value &&
      applyLsEvents(
        events.value as LSScheduledEvent[],
        getStoredEvents(currentUserId.value),
        getCancelledEvents(currentUserId.value)
      )) as ScheduledEvent[]
);

// Future Events
const futureEvents = computed(() => filterEvents(allEvents.value, 1));
const nextEvent = computed(() => futureEvents.value[0]);
const hasFutureEvents = computed(() => !!futureEvents.value.length);

// Past Events
const pastEvents = computed(() => filterEvents(allEvents.value, -1).reverse());
const lastEvent = computed(() => pastEvents.value[0]);
const hasPastEvents = computed(() => !!pastEvents.value.length);

const setupHook = (force: boolean = false) => {
  const { onResult } = useApolloQuery(
    useGetScheduledEventsQuery,
    { input: { userId: currentUserId.value } },
    {
      data: events,
      pending: isReloading.value ? isReloading : isLoading,
    },
    {
      placeholderPick: ({ getScheduledEventsForUser: res }) => res,
      force,
    }
  );

  onResult(({ getScheduledEventsForUser: res }) => {
    if (res) {
      events.value = res as ScheduledEvent[];
    }
  });
};

const loadingRescheduleLink = ref(false);
const getEventRescheduleLink = (scheduledEventId: string, force = false) =>
  useApolloQuery(
    useGetRescheduleLinkQuery,
    { input: { scheduledEventId } },
    { pending: loadingRescheduleLink },
    { force }
  );

const markEventAsCancelled = (eventId: string) => {
  const event = events.value.find((e) => e.id === eventId);

  const cancelledLSEvents = getCancelledEvents(currentUserId.value);
  saveInLocalStorage(
    `${currentUserId.value}:${CANCELLED_EVENT_KEY}`,
    JSON.stringify([...cancelledLSEvents, eventId])
  );

  if (event) {
    events.value = [
      ...events.value.filter((e) => e.id !== eventId),
      {
        ...event,
        cancelled: true,
      },
    ];
  }
};

const isCancellingEvent = ref(false);
const cancelEvent = (eventId: string) => {
  const { mutate, onDone, onError } = useApolloMutation(
    useCancelScheduledEventMutation,
    { pending: isCancellingEvent }
  );

  mutate({
    input: {
      scheduledEventId: eventId,
    },
  });

  return {
    onDone,
    onError,
  };
};

const addStubEvent = (event: ScheduledEvent) => {
  const currentLSEvents = getStoredEvents(currentUserId.value).filter(
    (e) => e.calendlyEventId !== event.calendlyEventId
  );

  saveInLocalStorage(
    `${currentUserId.value}:${STUB_EVENT_KEY}`,
    JSON.stringify([
      ...currentLSEvents,
      {
        ...event,
        addedAt: new Date().toISOString(),
      },
    ])
  );

  events.value = [...events.value, event];
};

const refreshEvents = () => {
  isReloading.value = true;
  refreshCount.value += 1;
  setupHook(true);
};

const useEvents = (vueStore?) => {
  if (!init) {
    init = true;
    setupHook();
  }

  if (vueStore) {
    store = vueStore;
  }

  return {
    allEvents,
    isLoading,
    events,
    futureEvents,
    nextEvent,
    pastEvents,
    hasFutureEvents,
    lastEvent,
    hasPastEvents,
    loadingRescheduleLink,
    isCancellingEvent,
    refreshEvents,
    addStubEvent,
    getEventRescheduleLink,
    markEventAsCancelled,
    cancelEvent,
  };
};

export default useEvents;
