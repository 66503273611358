import { useApolloQuery } from '@/gql/apolloWrapper';
import {
  UpcomingNonCoreSubscriptionBillingChange,
  useGetNonCoreSubscriptionBillingChangesQuery,
} from '@/gql/generated';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import isAdmin from '@/utils/isAdmin';
import { ref } from '@nuxtjs/composition-api';

let init;

const isLoading = ref(false);
const upcomingBillingChanges = ref<UpcomingNonCoreSubscriptionBillingChange[]>(
  []
);

const setupHook = (tier, force) => {
  const { companyId } = useCurrentCompany();
  if (isAdmin.value) {
    const { onResult } = useApolloQuery(
      useGetNonCoreSubscriptionBillingChangesQuery,
      {
        data: {
          companyId: companyId.value,
          tierNumber: tier,
        },
      },
      { pending: isLoading },
      { force }
    );

    onResult(({ getNonCoreSubscriptionBillingChanges: res }) => {
      upcomingBillingChanges.value = res;
    });
  }
};

const useUpcomingBillingChanges = (tier: number, force = false) => {
  if (!init || force) {
    setupHook(tier, force);
  }

  return {
    isLoading,
    upcomingBillingChanges,
  };
};

export default useUpcomingBillingChanges;
